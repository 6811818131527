//PreJoinScreens.tsx

import { useEffect, useState, useContext, FormEvent } from 'react';
//import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { LinkContext } from '../../index';
//import { LinkContext } from '../../App';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}
const BaseChars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ._-";

function convertBase(input: string, inputBase: number, outputBase: number): string {
  const trimmedInput = input.replace(new RegExp(`[^${BaseChars.substring(0,inputBase).replace(/-/g, '\\-')}]`, 'g'), '');
  if (trimmedInput === "") return "0";

  const inputNumbers = convertStringToBaseArray(input);
  const x: number[] = new Array(input.length + 1).fill(0);
  const y: number[] = new Array(input.length + 1).fill(0);

  // Find min power of output base
  x[0] = 1;
  let exponent = 0;
  while (compareArrays(x, inputNumbers) <= 0) {
    multiplyArray(x, outputBase, inputBase);
    exponent++;
  }

  // Reset x and calculate power one less than before
  x.fill(0);
  x[0] = 1;
  for (let i = 0; i < exponent - 1; i++) {
    multiplyArray(x, outputBase, inputBase);
  }

  const output = new Array(exponent).fill(0);
  for (let i = exponent - 1; i >= 0; i--) {
    let multiple = 0;
    y.fill(0);
    do {
      addArray(y, x, inputBase);
      multiple++;
    } while (compareArrays(y, inputNumbers) <= 0);
    multiple--;
    output[i] = multiple;
    y.fill(0);
    addArray(y, x, inputBase);
    multiplyArray(y, multiple, inputBase);
    subtractArray(inputNumbers, y, inputBase);
    x.fill(0);
    x[0] = 1;
    for (let j = 0; j < i - 1; j++) {
      multiplyArray(x, outputBase, inputBase);
    }
  }
  return convertBaseArrayToString(output);
}

function convertStringToBaseArray(input: string): number[] {
    return input.split('').reverse().map(char => BaseChars.indexOf(char));
}

function multiplyArray(array: number[], multiplier: number, baseValue: number): void {
    let carry = 0;
    for (let i = 0; i < array.length; i++) {
        const value = array[i] * multiplier + carry;
        array[i] = value % baseValue;
        carry = Math.floor(value / baseValue);
    }
}

function addArray(array: number[], addend: number[], baseValue: number): void {
    let carry = 0;
    for (let i = 0; i < array.length; i++) {
        const value = array[i] + addend[i] + carry;
        array[i] = value % baseValue;
        carry = Math.floor(value / baseValue);
    }
}

function subtractArray(array: number[], subtrahend: number[], baseValue: number): void {
    for (let i = array.length - 1; i >= 0; i--) {
        if (array[i] < subtrahend[i]) {
            let j = i + 1;
            while (array[j] === 0) {
                array[j] = baseValue - 1;
                j++;
            }
            array[j]--;
            array[i] += baseValue;
        }
        array[i] -= subtrahend[i];
    }
}

function compareArrays(a: number[], b: number[]): number {
    for (let i = Math.max(a.length, b.length); i >= 0; i--) {
        const aValue = i < a.length ? a[i] : 0;
        const bValue = i < b.length ? b[i] : 0;
        if (aValue !== bValue) return aValue - bValue;
    }
    return 0;
}

function convertBaseArrayToString(array: number[]): string {
    return array.reverse().map(index => BaseChars[index]).join('');
}

export default function PreJoinScreens() {
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const [step, setStep] = useState(Steps.roomNameStep);
  const [mediaError, setMediaError] = useState<Error>();

  const { URLRoomName } = useParams<{ URLRoomName?: string }>();
  const [name, setName] = useState<string>('');
  const [roomName, setRoomName] = useState<string>('');

  //Define Function between here

  const parseRoomName = (combinedString: string | undefined): [string, string] => {
    if (!combinedString) {
      return ['', '']; // Return empty strings if the input is undefined
    }
    const base16String = convertBase(combinedString, 62, 16);
    const blockId = base16String.slice(1, 33);
    const base16Username = base16String.slice(33);
    const username = convertBase(base16Username, 16, 65);
    return [blockId, username];
  };

  const c = parseRoomName(URLRoomName);
  const a = c[0];
  const b = c[1];
  const link = 'https://theblock.xyz/verify';

  const { setLinkValue } = useContext(LinkContext);
  // Call setLinkValue to update the link
  useEffect(() => {
    setLinkValue(link);
  // eslint-disable-next-line
  }, [link]);

  //and here.

  useEffect(() => {
    if (a) {
      setRoomName(a);
      if (b) {
        setName(b);
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [user, URLRoomName, a, b]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    // @ts-ignore
    if (!window.location.origin.includes('twil.io') && !window.STORYBOOK_ENV) {
      window.history.replaceState(null, '', window.encodeURI(`/${roomName}${window.location.search || ''}`));
    }
    setStep(Steps.deviceSelectionStep);
  };

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={name}
          roomName={roomName}
          setName={setName}
          setRoomName={setRoomName}
          handleSubmit={handleSubmit}
        />
      )}
      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen name={name} roomName={roomName} setStep={setStep} />
      )}
    </IntroContainer>
  );
}
